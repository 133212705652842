@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500;600&family=Nunito+Sans:wght@200;300;400;700;800;900&display=swap');

.fontNunito {
    font-family: 'Nunito Sans', sans-serif;
}

.fontManrope {
    font-family: 'Manrope', sans-serif;
}


.sellerpage_landing {
    /* margin-left:2%   solid #a81818; */
    /* padding: 5%; */

}

.part1_landing {
    background-color: #F5F5F5;  
    min-height: 85vh;
    max-height: 300vh;
    position: relative;
    padding: 1% 1% 2%;
}

.part2_landing {
    background-color: #D5F5EA;

    padding: 13% 0% 8% 0%;
}

.part3_landing {
    background-color: #F5F5F5;
    padding:5% 4% 7% 4%;
}

.part4_landing {
    background-color: #F5F5F5;
    padding: 6% 6% 10%;

}

.part5_landing {
    background-color: #F5F5F5;
    padding: 6%;


}

.part6_landing {
    background-color: #F5F5F5;
    padding: 4% 6% 4% 6%;

}

.footer2_landing {
    background-color: #27357A;

    padding: 2% 6vw;




}

.lastfooter_landing {
    background-color: #F5F5F5;
    padding: 0.5% 6vw;
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    font-weight: 400;
    color: #050E27;
}


.headerpart_landing {
    display: flex;
    justify-content: space-between;
    padding: 1.05rem 2.3rem 2.3rem 2.3rem;
    align-items: center;
    margin-left: 1.5%;
    margin-right: 1.5%;
   

}

.brakeup_logo_landing {
    width: 9.5vw;

    margin-left: 11%;

    align-self: flex-start;
    object-fit: contain;
    max-width: 116px;
}

.hyperlinks_landing {
    display: flex;
    justify-content: space-evenly;
    width: 65%;
    font-size: 18px;
    text-decoration: none;
    color: #000000;
}

.mhover_landing:hover {
    background-color: #27357A;
    border: none;
    color: white;
    padding: 9px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border-radius: 20px;
    white-space: nowrap;
    min-width: 9vw;

}

.mhover_landing {
    color: black;
    padding: 9px;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    font-size: 18px;
    min-width: 9vw;
}


.button1_landing {
    position: relative;
    background-color: #FFDF81;

    border: none;
    color: black;
    padding: 9px;
    width: 12vw;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 20px;
}


.button1_landing:hover {
    background: #f3cb52;
    box-shadow: 0px 2px 10px 5px #dae2e1;
    color: #000;
    opacity: 0.5;
}

.button1_landing:after {
    content: "";
    background: #f5f5f5;
    display: block;
    position: absolute;
    padding-top: 28%;
    padding-left: 86%;

    margin-top: -23%;
    opacity: 0;
    transition: all 0.8s;
}

.button1_landing:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}

.signin_lp_landing {

    width: 50%;

}

.signinbutton1_landing {
    position: relative;
    background-color: #27357A;

    border: none;
    color: white;
    padding: 12px;
    width: 124px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 23px;
    margin-top: 7%;
    font-weight: 600;
}

.signinbutton1_landing:hover {
    background: #5466c0;
    box-shadow: 0px 2px 10px 5px #dae2e1;

}

.signinbutton1_landing:after {
    content: "";
    background: #f5f5f5;
    display: block;
    position: absolute;
    padding-top: 28%;
    padding-left: 86%;

    margin-top: -23%;
    opacity: 0;
    transition: all 0.8s;
}

.signinbutton1_landing:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}

.signinbutton2_landing {
    position: relative;
    background-color: #D5F5EA;
    border: 1px solid #27357A;

    box-shadow: 0px 8px 15px #00000029;
    color: black;
    padding: 9px;
    min-width: 8.5rem;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    cursor: pointer;
    border-radius: 20px;

}

.signinbutton2_landing:hover {
    background: #4e579c;

    box-shadow: 0px 2px 10px 5px #dae2e1;

}

.signinbutton2_landing:after {
    content: "";
    background: #f5f5f5;
    display: block;
    position: absolute;
    padding-top: 28%;
    padding-left: 86%;

    margin-top: -23%;
    opacity: 0;
    transition: all 0.8s;
}

.signinbutton2_landing:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}


.createaccountbutton_landing {
    position: relative;
    background-color: #27357A;

    border: none;
    color: white;
    padding: 11px;
    width:139px;

    text-align: center;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    border-radius: 56px;

}
.sidebuttonmob_landing{
    
    border:none ;
    outline:none;
}


.createaccountbutton_landing:hover {
    background: #5466c0;
    box-shadow: 0px 2px 10px 5px #dae2e1;

}

.createaccountbutton_landing:after {
    content: "";
    background: #f5f5f5;
    display: block;
    position: absolute;
    padding-top: 28%;
    padding-left: 86%;

    margin-top: -23%;
    opacity: 0;
    transition: all 0.8s;
}

.createaccountbutton_landing:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}

.signinbody1_landing {
    display: flex;
    justify-content: space-between;
    margin-top: 8%;

    margin-left: 5.5%;
    margin-right: 3%;

}

.signinbody2_landing {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10%;
}

#heading2 {
    display: block;
    text-align: center;
    font-size: 32px;
    color: #27292E;
    margin-top: -10%;
}

#heading1 {
    font-family: Manrope, medium;
    font-size: 32px;
    color: #27292E;
    margin-bottom: 9%;
}


.bodycontent2_landing {
    font-size: 20px;
    color: #27292E;
}

.image1_landing {
    width: 50%;
    margin-top: -9%;


}

.image2_landing {
    width: 42%;
    position: relative;
   

}
.boxoverlay_landing{
    position: absolute;
    z-index: 9;
  margin: 30px;
  /* background: #009938; */
}

.image11_landing {}

#text1 {
    font-size: 16px;
    font-weight: 400;
    color: #27292E;
    margin-top: 20%;
    margin-left: 6%;
}

.buttons_landing {
    display: flex;
    margin-top: 10%;

    justify-content: space-around;
    width: 23rem;
}

.createlottie_landing {
    color: #27292E;
}

.heading3_landing {
    display: block;
    text-align: center;
    font-size: 32px;
    color: #27292E;
    font-weight: 400;
}

.successstories1_landing {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding: 6%;

    align-items: center;
    justify-content: space-between;
    background-color: white;


}

.successstories2_landing {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding: 6%;

    align-items: center;
    justify-content: space-between;
    background-color: white;


}

.h4_landing {
    font-size: 22px;
    font-weight: 500;
    color: #27292E;
}

.footerlogo_landing {
    width: 8vw;

    color: white;

}

.footerfont_landing {
    font-size: 12px;
    color: #DBDBDB;

}

.footerdiv_landing {
    display: flex;
    justify-content: space-between;

}

.address1 _landing{
    text-align: right;
}

.photo1_landing {
    margin-bottom: 7%;
    width: 20.4vw;
    border: 9px solid #FFFFFF;
    filter: drop-shadow(0px 0px 6px #00000029);
}

.photo2_landing {
    margin-bottom: 7%;
    width: 20.4vw;
    border: 9px solid #FFFFFF;
    filter: drop-shadow(0px 0px 6px #00000029);
}

.header_logo_landing {
    align-self: center;
}

#story1 {
    font-size: 13px;
    text-align: left;

}

.story2_landing {
    font-size: 14px;
    text-align: right;
    color: #4D4C4C;
    font-weight: 400;

}

.lottie1_landing {
    position: relative;
    left: 8%;
    width: 50px;

}

.lottie2_landing {
    position: absolute;
    display: flex;
    width: 16%;
    left: 55%;

}

.lottie3_landing {
    position: relative;

    width: 9%;
    left: 8%;

}

.lottie4_landing {
    position: absolute;
    display: flex;
    width: 18%;
    left: 71%;
    margin-top: 19%;
}

.lotties {
    /* display: flex; */
}

.icons_seller_landing {
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 7%;
    margin-top: 1%;
}

.imageproperties_landing {
    width: 9.5vw;
    min-width: 50px;
}

.large_seller_landing {
    text-align: center;
}

.largest_landing {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.name12_landing {
    display: flex;
    flex-direction: column;
    font-size: 22px;
    font-weight: 500;
    color: #4D4C4C;

}

.rbr_landing {
    font-size: 16px;

}

.rbrstory_landing {
    font-size: 18px;
    font-weight: 400;
}

.mySlides_landing {
    display: none;
}

.dot_landing {

    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.active1_landing {
    background-color: #717171;
}




/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
    .text_landing {
        font-size: 11px
    }
}

.sell_landing {

    display: grid;
    grid-template-areas: "sl sl sl sl";
    margin-top: 6%;
    justify-content: space-evenly;

}


.rectanglebg_landing {
    width: 20vw;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/landingpage/How to Sell on Brakeup Rectangle.png");
    background-position: center;
    background-size: contain;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 22px;
    font-weight: 500;
}



.lottie5_landing {
    width: 18vw;
    margin-top: -33%;
    margin-bottom: -12%;
}

.lottiesp_landing {
    width: 10vw;
    margin-top: 16%;
    margin-bottom: 11%;
}

.lotties_landing {
    width: 100%;

    position: relative;
}

.lottiebg_landing {
    width: 100%;

}

.lot_landing {
    width: 47vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
}

.lottieA_landing {
    position: absolute;
    top: 2vw;
    left: 25vw;
    width: 8vw;
}

.lottieB_landing {
    position: absolute;
    top: 3.5vw;
    left: 48.7vw;
    width: 6vw;
}

.lottieC_landing {
    position: absolute;
    top: 14vw;
    left: 37vw;
    width: 7vw;
}

.lottieD_landing {
    position: absolute;
    top: 13vw;
    left: 58vw;
    width: 11vw;
}

.createA_landing {
    position: absolute;
    top: 0vw;
    left: 8vw;
    width: 15vw;
}

.createB_landing {
    position: absolute;
    top: 15vw;
    left: 19vw;
    width: 15vw;
}

.createC_landing {
    position: absolute;
    top: 0vw;
    left: 59vw;
    width: 33vw;
}

.createD_landing {
    position: absolute;
    top: 14vw;
    left: 70vw;
    width: 20vw;
}


.arrow1_landing {
    width: 9vw;

    /* margin-left: 43%; */
    /* margin-right: auto; */
    position: absolute;
    /* margin-top: -7%; */
    right: 45%;
    bottom: 0;
}

.underline_image_landing {
    width: 16vw;
    margin-left: auto;
    margin-right: auto;
   

}

.sellsymbol_landing {
    width: 3vw;
}

.searchlist_landing {
    display: flex;
    align-items: center;
}

.sellingdata_landing {

    font-size: 16px;
    font-weight: 400;
    margin-top: 8%;
    margin-left: 6%;

}

.sproduc_landingt {
    text-align: center;
    margin-bottom: 5%;
}

.sstory_landing {
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.story_seller_landing {
    padding: 9%;
}

.about_landing {
    font-size: 12px;
    font-weight: 300;
    cursor: pointer;
}


.email1_landing {
    font-size: 12px;
    font-weight: 300;
}

.registeredaddress_landing {
    font-size: 12px;
    font-weight: 600;
}

.brakeupinter_landing {
    font-size: 12px;
    font-weight: 300;
}

.aboutcolor_landing {
    color: white;
    text-decoration: none;
}

@media (min-width: 320px) and (max-width:480px) {

    .fontheading_landing,
    .name12_landing {
        font-size: 12px;

    }

    .sellingdata_landing {
        font-size: 15px;
    }

    .rbrstory_landing {
        font-size: 12px;
    }

    .fontcontent_landing,
    .story2_landing,
    .rbr_landing {
        font-size: 9px;
    }

    .underline_image_landing {
        margin-top: -11%;
    }

}

@media (min-width: 481px) and (max-width:791px) {

    .fontheading_landing,
    .name12_landing {
        font-size: 15px;

    }

    .sellingdata_landing {
        font-size: 17px;
    }

    .rbr_landing,
    .rbrstory_landing {
        font-size: 13px;
    }

    .story2_landing {
        font-size: 11px;
    }

    .fontcontent_landing {
        font-size: 12px;
    }

    .underline_image_landing {
        margin-top: -10%;
    }
}

@media (min-width: 320px) and (max-width:480px),
(min-width: 481px) and (max-width:791px) {
    .sellerpage_landing {
        display: none;
    }

    .part1_landing {
        background-color: white;
        min-height: 63vh;
        max-height: 375vh;
    }

    .part3mobile_landing {
        background-color: white;
    }

    .part4_landing {
        background-color: white;
    }

    .part5_landing {
        background-color: white;
    }

    .part6_landing {
        background-color: white;
    }

    .sellerpagemobile_landing {
        display: block;
        background-color: white;
    }



    .brakeup_logo1_landing {

        min-width: 24px;
        max-width: 68px;

    }

    .top-header_landing {

        height: 5em;
        left: 0;

        position: fixed;
        right: 0;
        top: 0;
        z-index: 10;
        background-color: white;
        box-shadow: 0px 2px 10px #00000026;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem;
    }
    
      
    .mobilesidebar_landing {
        font-family: Manrope, medium;
       font-size: 18px;
       color: #000;
       text-decoration: none;

    }
      
     

    .listfont_landing {
        align-self: center;
        size: 22px;

    }


    .signinbody11_landing {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #heading1 {
        font-size: 18px;
        font-weight: 500;
    }

    .image11_landing {

        width: 77vw;
        margin-top: 7%;
    }

    .signin_lp_landing {
        width: 75vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .signinbody22_landing {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .signinbutton11_landing {
        display: block;
        margin-left: auto;
        margin-right: auto;
        background-color: #27357A;
        /* Green */
        border: none;
        color: white;
        padding: 9px;
        width: 45%;
        text-align: center;
        text-decoration: none;
        font-size: 15.5px;
        cursor: pointer;
        border-radius: 20px;
        margin-top: 5%;

    }

    .arrow11_landing {
        width: 16%;
        transform: rotate(360deg);
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .lot1_landing {
        width: 100%;
        transform: rotate(90deg);
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 18%;
        margin-top: 19%;
        padding: 5%;
    }

    .sell1_landing {
        grid-template-areas:
            "sl s2"
            "sl s2"
            "sl s2"
            "sl s2"
        ;
    }

    .content11_landing {
        display: flex;
        justify-content: space-evenly;

    }

    .searchlist1_landing {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 32%;
    }

    .sellsymbol1_landing {
        width: 6vw;
        transform: rotate(90deg);
    }

    .image2_landing {
        width: 54%;
    }

    .rectanglebg1_landing {

        width: 38vw;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("../../assets/images/landingpage/How to Sell on Brakeup Rectangle.png");
        background-size: contain;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .about1_landing {
        display: flex;
        justify-content: space-around;
        text-decoration: none;

        font-size: 12px;
    }

    .createA1_landing {
        position: absolute;
        top: -13vw;
        left: 23vw;
        width: 33vw;

        font-weight: 400;
    }

    .createB1_landing {
        position: absolute;
        top: 8vw;
        left: 54vw;
        width: 36vw;

    }

    .createC1_landing {
        position: absolute;
        top: 29vw;
        left: 9vw;
        width: 41vw;

    }


    .createD1_landing {
        position: absolute;
        top: 53vw;
        left: 52vw;
        width: 29vw;

    }

    .lottieA1_landing {
        position: absolute;
        top: -12vw;
        left: 54.4vw;
        width: 14vw;
    }

    .lottieB1_landing {
        position: absolute;
        top: 13vw;
        left: 33.8vw;
        width: 9vw;
    }

    .lottieC1_landing {
        position: absolute;
        top: 34vw;
        left: 57vw;
        width: 10vw;
    }

    .lottieD1_landing {
        position: absolute;
        top: 52vw;
        left: 30vw;
        width: 20vw;
    }

    .lottiesp1_landing {
        width: 16vw;
        margin-top: 14%;
        margin-bottom: 1%;
    }

    .lottie5_landing {

        width: 28vw;
        margin-top: -28%;
        margin-bottom: -12%;

    }

    .createaccount1_landing {
        text-align: center;
    }

    .buttons1_landing {
        display: flex;
        margin-top: 10%;

        justify-content: space-around;
    }

    #heading1,
    #heading2 {
        font-size: 18px;
    }

    .bodycontent1_landing,
    .bodycontent2_landing {
        font-size: 14px;
        text-align: center;
    }


    #text1 {
        font-size: 12px;
        margin-left: 0%;
        text-align: center;
    }

    .heading3_landing {
        font-size: 18px;
        margin-top: 8%;
        margin-bottom: 6%;
    }


    .successstoriesm1_landing {
        
    display: flex;
   margin:2px;
    padding: 6%;

    align-items: center;
    justify-content: space-between;
    background-color: white;
    /* border:solid 1px; */
     /* box-shadow: 0px 2px 10px 2px #00000026; */
     /* border: 9px solid #FFFFFF; */
    filter: drop-shadow(1px 2px 4px #00000029);

    }

    .successstoriesm2_landing {
        display: flex;
        margin:2px;
        padding: 6%;

        align-items: center;
        justify-content: space-between;
        background-color: white;
        /* border:solid 1px; */
        /* border: 9px solid #FFFFFF; */
        filter: drop-shadow(1px 2px 4px #00000029);
        /* box-shadow: 0px 2px 10px 2px #00000026; */

    }

    .sellingdata_landing {

        margin-top: 2%;
    }

    .lastfootermobile_landing {
        font-size: 9px;
        background-color: #F5F5F5;
        padding: 1% 0vw;
        display: flex;
        justify-content: space-around;
        font-size: 10px;
        font-weight: 400;
        color: #050E27;
    }

    .icons_seller_landing {
        font-size: 10px;
    }


    .rbrstory_landing {

        font-weight: 400;
    }

    .footer2_landing {
        padding: 1% 8vw;
    }

    .mySlides1 {
        display: none;

    }

    .signinbutton2_landing {
        width: 39.3%;
    }

    .dot1_landing {

        height: 13px;
        width: 13px;
        margin: 0 2px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
        transition: background-color 0.6s ease;
    }

    .part5_landing {
        margin-bottom: 6%;
    }

    .active1_landing {
        background-color: #717171;
    }

    /* Fading animation */
    
}

@media (min-width: 792px) and (max-width:1024px) {

    .sellerpage_landing {
        display: block;
    }

    .sellerpagemobile_landing {
        display: none;
    }

    .fontheading_landing {
        font-size: 16px;

    }

    .fontcontent_landing {
        font-size: 12px;
    }

    .bodycontent1_landing,.bodycontent2_landing {
        font-size: 14px;
    }

    #heading1,
    #heading2,
    .heading3_landing,
   
    .name12_landing {
        font-size: 16px;
    }

    .rectanglebg_landing,
    .mhover_landing,
    
    .hyperlinks_landing,
    .button1_landing,
    

    .signinbutton1_landing,
    .createaccount_landing,
    .icons_seller_landing,
    #text1,
    .footerfont_landing,
    .rbrstory_landing,
    .rbr_landing,
    .story2_landing {
        font-size: 12px;
    }
    .sellingdata_landing{
        font-size: 11px;
    }
    .lastfooter_landing {
        font-size: 10px;

    }
}

@media (min-width: 1025px) and (max-width:1200px) {

    .sellerpage_landing {
        display: block;
    }

    .sellerpagemobile_landing {
        display: none;
    }

    .fontheading_landing {
        font-size: 18px;

    }

    .fontcontent_landing {
        font-size: 14px;
    }

    #heading1,
    #heading2,
    .heading3_landing,
    .name12_landing
    {
        font-size: 18px;
    }

    .story2_landing,
    .sellingdata_landing,
    .rbrstory_landing,
    .rbr_landing {
        font-size: 14px;
    }

    .rectanglebg_landing,
    .mhover_landing,
    
    .hyperlinks_landing,
    .button1_landing,
    .bodycontent1_landing,
    .bodycontent2_landing,
    .signinbutton1_landing,
    .createaccount_landing,
    .icons_seller_landing,
    #text1,
    .footerfont_landing {
        font-size: 16px;
    }

    .lastfooter_landing {
        font-size: 10px;
    }


}

@media (min-width:1201px) {
    .sellerpage_landing {
        display: block;
    }

    .sellerpagemobile_landing {
        display: none;
    }

    .lastfooter_landing {
        font-size: 10px;
    }

    .fontheading_landing,.bodycontent1_landing {
        font-size: 20px;

    }

    .fontcontent_landing {
        font-size: 16px;
    }

}