
.cuscare_headers {
    font-family: var(--font-family-manrope);
    font-style: normal;
    font-weight: 900;
    font-size: 1.3rem;

}

.cuscare_header_2{
    font-family: var(--font-family-manrope);
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
}

.cuscare_form{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.cuscare_descriptionbox {
    height: 200px ;
    width: 100%;
    font-family: var(--font-family-nunito_sans);
    font-weight: 600;
    font-style: normal;
    padding: 10px;
    border: 1px solid;
    border-radius: 3px;
    border-color:#afacac;;
}

.cuscare_descriptionbox ::placeholder {
    font-family: var(--font-family-nunito_sans);
    font-weight: 600;
    font-style: normal;
    color: #959595;
    white-space: nowrap;
}

.submit_msg_cuscare {
  
    background-color: var(--turbo);
    font-family: var(--font-family-nunito_sans);
    font-style: normal;
    font-weight: 700;
    white-space: nowrap;
    border-radius: 5px ;
    margin-top: 20px;
    padding: 5px 10px 5px 10px;
    font-size: 0.9rem;
}

.cuscare_lottie{
    width: 50vw;
}




@media screen and (max-width: 427px) {
    body{
        background: white !important;
    }

    .cuscare_headers {
        font-size: 1rem;
    }
    
    .cuscare_header_2{
        font-size: 0.8rem;
    }

    .cuscare_lottie{
        width: 100%;
        margin-top:20px;
    }
    
    .cuscare_form{

    }
    
    .cuscare_descriptionbox {
        font-size: 14px;
        height: 250px ;
    }
    
    .cuscare_descriptionbox ::placeholder {

    }
    
    .submit_msg_cuscare {
        font-size: 0.8rem;
    }
    
   

}

@media screen and (max-width: 799px) and (min-width: 428px) {

    body{
        background: white !important;
    }

    .cuscare_headers {
        font-size: 1.3rem;
    }
    
    .cuscare_header_2{
        font-size: 0.9rem;
    }

    .cuscare_lottie{
        width: 100%;
        margin-top:20px;
    }
    .cuscare_descriptionbox {
        font-size: 14px;
        height: 300px ;
    }

}

@media screen and (max-width: 1280px) and (min-width: 800px) {


    .cuscare_headers {
        font-size: 1.3rem;
    }
    
    .cuscare_header_2{
        font-size: 1rem;
    }
    .cuscare_descriptionbox {
        height: 170px ;
     
    }

   

}

@media screen and (min-width: 1920px) {


    .cuscare_headers {
        font-size: 1.5rem;
    }
    
    .cuscare_header_2{
        font-size: 1.1rem;
    }


}


