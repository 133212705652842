@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;500;600;800;900&display=swap");

.confirm {
  position: absolute;
  left: 4%;
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-family: "Manrope", sans-serif;
  font-family: "Nunito Sans", sans-serif;
  color: #999999;
}

.confirm:hover {
  color: #777777;
}

.warning {
  background-color: #f1b44c;
  border-color: #f1b44c;
  border: none;
  color: black !important;
  border-radius: 6px;
  height: 2rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer;
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  margin: -20px 10px 0 10px;
}
.warning:hover {
  background-color: #ca8e26;
}

.warning2 {
  background-color: #f1b44c;
  border-color: #f1b44c;
  border: none;
  color: black !important;
  border-radius: 6px;
  height: 2rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer;
  font-family: "Manrope", sans-serif;
  font-weight: 500;
}
.warning2:hover {
  background-color: #ca8e26;
}

.light {
  background-color: #fff;
  border-color: #fff;
  border: none;
  color: black;
  border-radius: 6px;
  height: 2rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer;
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  margin: -20px 10px 0 10px;
  box-shadow: 0px 0px 4px #00000033;
}

.light:hover {
  background-color: #d3d2cf;
}

.amazonContainer {
  width: 40%;
  justify-items: center;
}
.amazonHtmlContainer {
  width: 70%;
}

.SwalNormalContainer {
  width: 40%;
}

.btndarkBlue {
  background-color: #243678;
  color: #243678;
}
.ordertext text {
  padding-bottom: 5px;
  font-size: 16px;
}
.fontorders {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
}

.btnfont {
    font-size: 15px !important;
  }
.btnHoverOpacity:hover {
  opacity: 0.8;
}

.Orderloading {
    left: calc(50% - 150px);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 3px solid transparent;
  
    &.component-loader {
      z-index: 9999;
    }
}
.orderloading2{
    overflow-y: hidden !important;

	    overflow-x: hidden !important;
}

/* Modals */

.checkmark__circle{
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 50px;
    stroke-miterlimit: 10;
    stroke: #00FF00;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
  }
  .cne_checkmark{
    
    height: 50px;
    border-radius: 50%;
    display: block;
    stroke-width: 4px;
    stroke: #FFF;
    stroke-miterlimit: 10;
   
    box-shadow: inset 0px 0px 0px #00FF00;
    animation:  ease-in-out .4s forwards, scale .3s ease-in-out .9s both
  }
    .checkmark__check{
        transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        border-radius: 6px;
        animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
    }@keyframes stroke{
        100%{stroke-dashoffset: 0}
    }@keyframes scale{
        0%, 100%{transform: none}50%{transform: scale3d(1.1, 1.1, 1)}
    }
    @keyframes fill{100%{box-shadow: inset 0px 0px 0px 30px #7ac142}
  }

  .fontorders p{
    margin-bottom:5px
  }

  .btn:disabled {
    cursor: not-allowed;
    pointer-events: auto !important;
  }